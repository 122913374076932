export default function gallery() {
  const items = Array.from(document.querySelectorAll(".gallery__item"));
  const lightbox = document.querySelector(".lightbox");
  if (items.length && lightbox) {
    initGallery(items, lightbox);
  }
}

function initGallery(items, lightbox) {
  let activeIndex = null;
  items.map((item, index) => {
    item.addEventListener("click", (e) => {
      activeIndex = index;
      lightbox.classList.add("lightbox--active");
      const {
        buttonLeft,
        buttonRight,
        imageElement,
        count,
        title,
      } = addLightBoxImageWithContolls({
        lightbox,
        current: item,
        count: index + 1,
        total: items.length,
      });
      setToLandscapeOrPortrait(imageElement, lightbox.firstElementChild);

      buttonLeft.addEventListener("click", (e) => {
        e.stopPropagation();
        activeIndex = activeIndex === 0 ? null : activeIndex - 1;
        updateCurrentImage(
          activeIndex,
          imageElement,
          lightbox,
          items[activeIndex],
          count,
          title
        );
      });
      imageElement.addEventListener("click", (e) => {
        e.stopPropagation();
        activeIndex = activeIndex === items.length - 1 ? null : activeIndex + 1;
        updateCurrentImage(
          activeIndex,
          imageElement,
          lightbox,
          items[activeIndex],
          count,
          title
        );
      });
      buttonRight.addEventListener("click", (e) => {
        e.stopPropagation();
        activeIndex = activeIndex === items.length - 1 ? null : activeIndex + 1;
        updateCurrentImage(
          activeIndex,
          imageElement,
          lightbox,
          items[activeIndex],
          count,
          title
        );
      });
    });
  });

  lightbox.addEventListener("click", (e) => {
    closeLightbox(lightbox);
  });
}

function updateCurrentImage(
  activeIndex,
  imageElement,
  lightbox,
  newImage,
  count,
  title
) {
  if (activeIndex === null) {
    closeLightbox(lightbox);
  } else {
    count.innerHTML = activeIndex + 1;
    imageElement.src = newImage.src;
    imageElement.alt = newImage.alt;
    title.innerHTML = newImage.alt;
    setToLandscapeOrPortrait(imageElement, lightbox.firstElementChild);
  }
}

function closeLightbox(lightbox) {
  lightbox.classList.remove("lightbox--active");
  lightbox.removeChild(lightbox.firstElementChild);
}

function setToLandscapeOrPortrait(lightboxImage, lightboxWrapper) {
  lightboxImage.addEventListener("load", () => {
    if (lightboxImage.classList.contains("lightbox__current-image--portrait")) {
      lightboxImage.classList.remove("lightbox__current-image--portrait");
      lightboxWrapper.classList.remove("lightbox__inner-wrapper--portrait");
    }
    if (lightboxImage.height > window.innerHeight * 0.8) {
      lightboxImage.classList.add("lightbox__current-image--portrait");
      lightboxWrapper.classList.add("lightbox__inner-wrapper--portrait");
    }
    lightboxWrapper.classList.add("lightbox__inner-wrapper--visible");
  });
}

function addLightBoxImageWithContolls({ lightbox, current, count, total }) {
  const template = document.createElement("template");
  template.innerHTML = `
    <div class="lightbox__inner-wrapper">
      <div class="lightbox__image-wrapper">
        <img class="lightbox__current-image" src="${current.currentSrc}" alt="${current.alt}"></img>
      </div>
      <div class="lightbox__controlls">
        <div class="lightbox__controlls-first">
          <button class="lightbox__button lightbox__button--left">‹</button>
          <button class="lightbox__button lightbox__button--right">›</button>
          <div class="lightbox__title-wrapper">
            <span class="lightbox__count">${count}</span><span> / ${total}</span>
            <p class="lightbox__image-title">${current.alt}</p>
          </div>
        </div>
        <button class="lightbox__button lightbox__button--close">×</button>
      </div>
    </div>`;
  const lightboxWrapper = template.content.firstElementChild;
  lightbox.appendChild(lightboxWrapper);

  return {
    buttonLeft: document.querySelector(".lightbox__button--left"),
    buttonRight: document.querySelector(".lightbox__button--right"),
    imageElement: document.querySelector(".lightbox__current-image"),
    count: document.querySelector(".lightbox__count"),
    title: document.querySelector(".lightbox__image-title"),
  };
}
