export default function headerSlideShow() {
  const slideshowElements = Array.from(
    document.querySelectorAll(".header__slide-show-image")
  );

  if (slideshowElements.length) {
    initSlideShow(slideshowElements);
  }
}

function initSlideShow(slideshowElements) {
  let timer = null;
  let activeElement = 0;

  rotateSlideShowItems();

  function rotateSlideShowItems() {
    const previouslyActive = document.querySelector(
      ".header__slide-show-image--active"
    );
    if (previouslyActive) {
      previouslyActive.classList.remove("header__slide-show-image--active");
    }
    slideshowElements[activeElement].classList.add(
      "header__slide-show-image--active"
    );

    slideshowElements[activeElement].addEventListener(
      "transitionend",
      function transitionEvent(e) {
        timer = setTimeout(rotateSlideShowItems, 1500);
        e.target.removeEventListener("transitionend", transitionEvent);
        activeElement =
          activeElement === slideshowElements.length - 1
            ? 0
            : activeElement + 1;
      }
    );
  }
}
